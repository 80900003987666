import { DestroyRef, Directive, ElementRef, EventEmitter, inject, Output, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WindowScrollService } from '@services/window-scroll.service';
import { openedDialogs } from '@technokratos-public/utils/openedDialogs';

const HEADER_HEIGHT = 93;
const HIDE_CLASS = 'main-page-header--hide';

@Directive({
	standalone: true,
	selector: '[scrollHeader]',
})
export class ScrollHeaderDirective {
	@Output() toggleHeader = new EventEmitter<boolean>();

	lastScroll = 0;

	private el = (inject(ElementRef).nativeElement as HTMLHeadingElement).parentElement!;
	private renderer2 = inject(Renderer2);
	private destroyRef = inject(DestroyRef);
	private openedDialogs = openedDialogs();

	constructor() {
		inject(Router)
			.events.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(() => {
				this.toggleHeader.emit(false);
				this.removeHide();
			});

		inject(WindowScrollService)
			.scrollY$.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(scrollPosition => {
				if (this.openedDialogs()) return;

				const containHide = this.el?.classList.contains(HIDE_CLASS) || false;
				const modalIsOpen =
					(scrollPosition <= HEADER_HEIGHT || this.lastScroll !== 0) &&
					(this.lastScroll <= HEADER_HEIGHT || scrollPosition !== 0);

				if (modalIsOpen && scrollPosition > this.lastScroll && !containHide && scrollPosition > 5) {
					this.renderer2.addClass(this.el, HIDE_CLASS);
					this.toggleHeader.emit(true);
				}

				if (modalIsOpen && scrollPosition < this.lastScroll && containHide) {
					this.removeHide();
				}

				if (scrollPosition === 0) {
					this.removeHide();
					this.toggleHeader.emit(false);
				}

				this.lastScroll = scrollPosition;
			});
	}

	removeHide() {
		this.renderer2.removeClass(this.el, HIDE_CLASS);
	}
}
