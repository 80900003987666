import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'tk-side-name',
	templateUrl: './side-name.component.html',
	styleUrls: ['./side-name.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNameComponent {}
